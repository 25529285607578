
#banner{
    background: $color1; 
    padding: 40px 20px;
    text-align: center;
    h2{
        color: #fff;
        max-width: 55%;
        font-size: 24px;
        display: block; 
        text-align: center;
        margin: 0 auto;
        font-weight: 500;
    }
}



.prices{
    table{
        width: 100%;
        tr{
            &:nth-child(odd){
                 background: rgba(30, 85, 93, 0.1);
            }
            &:nth-child(even){
                 background: rgba(30, 85, 93, 0.15);
            } 
           
            &:first-child{
                background: none; 
                
                td{
                    font-weight: bold;
                }
            }
            
           
            
            td{
                text-align: center;
                padding: 15px 10px ;
                color: #1E555D;
                line-height: 1;
                small{
                    
                }
                &:first-child{
                    text-align: left;
                    padding: 3px 10px;
                    font-size: 18px;
                    
    
                }
            }
        }
    }
    
    .size{
        background: rgba(30, 85, 93, 0.1);
        text-align: center; 
        padding: 20px;
        
        .icon i{
            font-size: 30px;
            color: #1E555D;
            margin-bottom: 10px;
        }
        .title{
            font-size: 20px;
            color: #1E555D;
        }
        .text{
            color: #1E555D;
        }
    }
}


.boxes{
    height: 370px;
    .left, .right{
        display: inline-block; 
        float: left; 
        height: 100%;
        width: 50%; 

        .overlay{
            background: rgba(227, 75, 11, 0.7);
            padding: 40px 80px;
            width: 100%;
            height: 100%;

            h2{
                color: #fff;
                font-size: 36px;
            }

            p{
                font-size: 16px;
                max-width: 72%;
                color: #fff;
            }
        }
    }
}

footer{
    background: $color1;
    padding: 30px 80px 30px;
    color: #fff;
    ul{
        width: 100%;
        list-style: none; 
        display: block;
        padding: 0; 
        margin: 0; 
        li{
            color: #fff; 
            font-size: 12px; 
            margin: 0 5px; 
            display: inline-block;
            &:after{
                content: '|';
                margin-left: 10px;
            }
            a{
                color: #fff; 
                font-size: 12px; 
                &:hover{
                    color: #C7C100;
                }
            }

            &:last-child{
                &:after{
                    display: none;
                }
            }

            &.dark{
                opacity: 0.7;
            }
        }

    }

}


.heading{
    

    .overlay{
        width: 44%; 
        background: rgba(41, 50, 83, 0.7);
        padding: 40px 80px 40px 100px;
        padding-bottom: 270px;

        h1{
            font-size: 62px;
            font-weight: 300; 
            color: #fff;
        }
        p{
            font-size: 20px;
            font-weight: 500; 
            line-height: 25px;
            color: #fff;
        }
    }
}


.main-content{

    margin: 0 40px;
    margin-top: -200px;
    border: 18px solid rgba(255,255,255,0.3);
    
    overflow: hidden;
    .border{
        background: #fff;
        width: calc(100% + 1px);
    }

    .room-boxes{
        .left, .right{
            height: 370px;
            float: left;
            display: inline-block; 
            @media (max-width: 991px){
                height: auto;
                float: none;
                display: block; 
            }

            h2{
                font-size: 42px;
                font-weight: 500;
                margin-top: 0;
                color: #002842;
            }
            p{
                font-size: 16px;
                font-weight: 500;
                color: #002842;
            }
        }

        .left{
            width: calc(44% - 7px);
            @media (max-width: 991px){
                width: 100%;
            }

            padding: 40px;
            &.dark{
                background: $color2;
                h2{

                    color: #fff;
                    margin-top: 0;
                }
                p{

                    color: #fff;
                }
            }

        }
        .right {
            width: calc(56% + 7px);
            @media (max-width: 991px){
                width: 100%;
                height: 370px;
            }
        }



    }

    .more-rooms{
        padding: 40px;
        background: $color2;
        h3{
            font-size: 34px; 
            font-weight: 500;
            margin-top: 0;
            color: #fff;
        }
        ul{
            list-style: none;
            li{
                a{
                    font-size: 16px;
                    font-weight: 500;
                    color: #fff;
                }
            }

        }
    }

    .rooms-boxes{

        .box{

            height: 370px;


            .overlay{
                width: 100%;
                height: 100%;
                padding: 40px;
                h2{
                    font-size: 34px;
                    color: #fff;
                    font-weight: 300;
                    margin-top: 0;
                }
                p{
                    font-size: 16px;
                    color: #fff;
                }

                &.green{
                    background: rgba(199, 193, 0, 0.8);
                }
                &.pink{
                    background: rgba(227, 75, 11, 0.8);
                }
                &.blue{
                    background: rgba(30, 85, 93, 0.8);
                }
            }

        }
        
        &.planning{
            padding: 40px;
            .box{
                height: 200px;
                
                .more-button{
                        padding: 2px 10px;
                }
                
                .overlay{
                    padding:20px; 
                }
                
                h2{
                    font-size: 20px;
                }
                
                &:first-child{
                    
                    background: rgba(199, 193, 0, 0.8);
                }
                
                &:nth-child(2){
                    background: rgba(227, 75, 11, 0.8);
                }
                &:nth-child(3){
                    background: rgba(30, 85, 93, 0.8);
                }
                &:nth-child(4){
                    background:rgba(30, 85, 93, 0.8);  
                }
                &:nth-child(5){
                     background: rgba(199, 193, 0, 0.8);
                }
                &:last-child{
                    background: rgba(227, 75, 11, 0.8);
                }
            }
        }

    }

    .rooms-bottom{
        height: 370px;
        .left{
            float: left;
            display: inline-block; 
            padding: 40px;
            width: calc(44% - 7px);
            height: 370px;
            h2{
                font-size: 34px;
                color: #fff;
                font-weight: 300;
                margin-top: 0;
            }
            p{
                font-size: 16px;
                color: #fff;
            }
        }
        .right{
            height: 370px;
            float: left;
            display: inline-block; 
            width: calc(56% + 7px);
        }
    }

    .maintext{
        padding: 40px;
        font-size: 15px;

        #room-images{
            position: relative;
            .item{
                width: 100%;

                span{
                    padding: 6px 12px; 
                    background: #BAD9B1;
                    color: #1E555D; 
                    text-transform: uppercase;
                    font-size: 10px;
                    font-weight: 900;
                    letter-spacing: 2px; 
                }

                h2{
                    font-size: 42px; 
                    color: $pink;
                    font-weight: 500;
                }

                p{
                    font-size: 16px; 
                    color: #fff;
                    font-weight: 500;
                    max-width: 70%;
                }
            }

            .owl-controls {
                overflow: auto;
                background: #fff;
                margin-top: 0;
                padding: 0;
                .owl-prev, .owl-next {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transition: all 0.3s ease-out;
                    -moz-transition: all 0.3s ease-out;
                    -o-transition: all 0.3s ease-out;
                    transition: all 0.3s ease-out;
                    background: rgba(255,255,255,0.7);
                    border-radius: 50%;
                    height: 50px;
                    width: 50px;
                    text-align: center;
                    i{
                        color: $dark-blue;
                        line-height: 50px;
                        font-size: 18px;
                    }

                    &:hover{
                        background: rgba(255,255,255,0.9);
                    }
                }

                .owl-prev {

                    left: 15px;
                    i{
                        margin-right: 2px;
                    }

                }

                .owl-next {


                    right: 15px;
                    i{
                        margin-left: 3px;
                    }
                }
            }

            .owl-dots {
                text-align: center;
                -webkit-tap-highlight-color: transparent;
                position: absolute; 
                top: 20px;
                right: 30px;

                .owl-dot {
                    display: inline-block;
                    zoom: 1;
                    *display: inline;

                    span {
                        width: 8px;
                        height: 8px;
                        margin: 7px;
                        background: #fff;
                        display: block;
                        -webkit-backface-visibility: visible;
                        transition: opacity 200ms ease;
                        border-radius: 30px;
                    }

                    &.active,
                    &:hover {
                        span {
                            background: #8BC53F;
                        }
                    }
                }
            }
        }

        h2{
            font-size: 34px; 
            font-weight: 700;
            color: #1E555D;
        }
        h3{
            color: #1E555D;
        }
        h4{
            color: #404041;
            font-size: 20px;
            font-weight: 500;
            line-height: 26px;
        }

        .capacity{
            background: rgba(30, 85, 93, 0.1);
            padding: 20px;
            margin: 40px 0;
            h5{
                font-size: 20px;
                color: #1E555D;
                font-weight: 700;
                margin-top: 0;
            }
            p{
                margin-bottom: 0;
                font-size: 14px;
            }
        }

        p{
            font-size: 15px;
            color: #404041;
        }
        
        ul li{
            color: #404041;
        }

    }


}

.calendar-page{

    .heading{
        .overlay{
            width: 100%;
        }
    } 

    .main-content{
        @media(max-width: 767px){
            border: 0;
        }
        
        .border{
            padding: 40px;
            @media(max-width: 767px){
                padding: 10px 0px;
            }
            h2{ 
                margin: 0;
                margin-right: 20px;
                display: inline-block;
                color: #1E555D;
                font-size: 20px;
                font-weight: 700; 
                @media(max-width: 767px){
                    display: block;
                    margin-bottom: 10px;
                    text-align: center;
                    margin-right: 0;
                }
                
            }
            
            
            
            .btn{
                border: 0px;
                margin-right: 8px;
                font-size: 12px;
                font-weight: 700;
                padding: 10px 15px;
                -webkit-transition: all 0.3s ease-out;
                -moz-transition: all 0.3s ease-out;
                -o-transition: all 0.3s ease-out;
                transition: all 0.3s ease-out;
                
                @media(max-width: 767px){
                    width: 25%;
                    margin-left: -4px;
                    margin-right: 0; 
                }
                @media(max-width: 520px){
                    
                    width: 50%;
                }
                
                &.dis{
                    background: #999;
                }
            }
            
            .btn-warning{
                background: $color2;
                &:hover{
                    background: darken($color2, 10%);
                }
            }
            .btn-primary{
                background: $color3;
                &:hover{
                    background: darken($color3, 10%);
                }
            }
            .btn-danger{
                background: $dark-blue;
                &:hover{
                    background: darken($dark-blue, 10%);
                }
            }
            .btn-success{
                background: $pink;
                &:hover{
                    background: darken($pink, 10%);
                }
            }
        }
    }
    
    #calendar{
        @media(max-width: 376px){
            margin-top: -20px;
        }
        h2{
            color: $color1;
            font-size: 34px;
            font-weight: 700;
        }
        
        .fc-center{
            @media(max-width: 767px){
                h2{
                    margin-right: 0;
                    margin-top: 20px;
                    margin-bottom: 0;
                    font-size: 25px;
                }
            }
        }
        
        .fc-head{
            th:nth-child(even){
                color: #fff;
                background: $color1;
                border: 0px;
                line-height: 50px;
            }
            th:nth-child(odd){
                color: #fff;
                background: $color2;
                border: 0px;
                line-height: 50px;
            }
        }
        
        @media(max-width: 376px){
            
            .fc-left, .fc-right{
                float: left;
                width: 100%;
                text-align: center;
            }
            
            .fc-toolbar > * > :first-child{
                margin-left: auto;
            }
            
            .fc-toolbar > * > :first-child{
                float: none;
            } 
            
            .fc-toolbar > * > *{
                float: none;
                margin-bottom: -12px;
                margin-top: 20px;
            }
            
        
        }
        
     
        
        .fc-day-grid-event{
            margin: 0;
            padding: 10px;
            @media(max-width: 767px){
                padding: 5px;
                
                .fc-time, .fc-title{
                    font-size: 11px;
                    display: block;
                }
            }
            border: 0px;
            border-radius: 0px;
            cursor: pointer;
            -webkit-transition: all 0.3s ease-out;
            -moz-transition: all 0.3s ease-out;
            -o-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
        }
        
        .amber{
            background: $color3;
            
            &:hover{
                background: darken($color3, 10%);
            }
        }
        
        .fabrick{
             background: $dark-blue;
             &:hover{
                background: darken( $dark-blue, 10%);
            }
        }
        .rattle{
            
            background: $pink;
            &:hover{
                background: darken( $pink, 10%);
            }
        }
         
        
            
    }

}

#caldetail{
    
    .modal-content{
        border: 20px solid $color1;
    }
    .modal-header{
        #modaltitle{
            color: $color2;
            font-size: 26px;
            font-weight: 700;
            
            @media(max-width: 767px){
                 font-size: 18px;
            }
            
            i{
                color: $color3;
            }
        } 
        
    }
    
    .modal-body{
        i{
                color: $pink;
            }
    }
    .btn-default{
        background: #C7C100;
        color: #fff;
        &:hover{
            background: darken(#C7C100, 10%);
            color: #fff;
        } 
    }
    
    
}

blockquote{
    border-left: 5px solid $pink;
    margin-top: 0px!important;
    color: #404041;
}

#contactForm{
    .left{
        
        width: 47%;
        float: left;
        
        @media (max-width: 767px){
            width: 100%;
            float: none;
        }
        
    }
    
    .right{
        width: 47%;
        float: right;
        @media (max-width: 767px){
            width: 100%;
            float: none;
        }
    }
    
    legend{
        color: $color2;
    }
    
}

.input-group .form-control{
    z-index: 1;
}

.ui-datepicker{
    padding: 10px!important;
}

.ui-widget .ui-widget{
    margin: 10px!important;
}

.ui_tpicker_time_label{
    display: none;
}

.ui_tpicker_time{
    font-size: 29px!important; margin: 10px; color: $pink;
    text-align: center;
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active{
    background: $pink!important;
}

.main-content.gallery{
    .maintext{
        padding: 30px;
    
        @media (max-width: 676px){
            padding: 10px;
        }
    }
    
    .mfp-no-margins img.mfp-img {
            padding: 0;
    }
    /* position of shadow behind the image */
    .mfp-no-margins .mfp-figure:after {
            top: 0;
            bottom: 0;
    }
    /* padding for main container */
    .mfp-no-margins .mfp-container {
            padding: 0;
    }
    
    .gallery-grid{
        .gallery-item{
            width: 50%;

            @media (max-width: 676px){
                width: 100%;
            }
            .gallery-image{
                border: 8px solid #ccc; 
                margin: 10px;
                display: block;
                cursor: pointer;
                &:hover{
                    border: 8px solid #999; 
                }
                img{
                    max-width: 100%;
                }

            }
        }
    }
}

