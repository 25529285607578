/*		Default Layout: 1200px and above. 
                Gutters: 24px.
                Outer margins: 48px.
                Leftover space for scrollbars @1200px: 32px.
-------------------------------------------------------------------------------
cols    1     2      3      4      5      6      7      8      9      10
px      68    160    252    344    436    528    620    712    804    896    */




/*		Tablet Layout: 1024px.
                Gutters: 24px.
                Outer margins: 28px.
                Inherits styles from: Desktop Layout.
-----------------------------------------------------------------
cols    1     2      3      4      5      6      7      8
px      68    160    252    344    436    528    620    712    */

@media only screen and (max-width: 1199px) {

    #header{


        .social{
            position: absolute;
            text-align: right;
            right: 30px;
            top: 30px;
        }
    }

    #banner{
        h2{
            max-width: 70%
        }
    }
    .heading{


        .overlay{
            width: 50%; 
            background: rgba(41, 50, 83, 0.7);
            padding: 40px 80px 40px 100px;
            padding-bottom: 270px;

            h1{
                font-size: 58px;
                font-weight: 300; 
                color: #fff;
            }
            p{
                font-size: 18px;
                font-weight: 500; 
                line-height: 22px;
                color: #fff;
            }
        }
    }


}



/*		Tablet Layout: 768px.
                Gutters: 24px.
                Outer margins: 28px.
                Inherits styles from: Default Layout.
-----------------------------------------------------------------
cols    1     2      3      4      5      6      7      8
px      68    160    252    344    436    528    620    712    */

@media only screen and (max-width: 991px) {

    .heading{


        .overlay{
            width: 50%; 
            background: rgba(41, 50, 83, 0.7);
            padding: 40px 80px 40px 100px;
            padding-bottom: 270px;

            h1{
                font-size: 45px;
                font-weight: 300; 
                color: #fff;
            }
            p{
                font-size: 16px;
                font-weight: 500; 
                line-height: 20px;
                color: #fff;
            }
        }
    }

    .boxes{
        .left, .right{

            .overlay{
                padding: 40px 80px;
                h2{
                    margin-top: 0px;
                }
                p{
                    max-width: 100%;
                }
            }
        }
    }

    #header{
        #menu{
            .mobile-wrapper{
                ul{
                    li{
                        a{
                            padding: 0 9px;
                            font-size: 13px;                           
                        }
                    }
                }
            }
        }
    }


    #slider{
        .item{
            height: 350px;
            padding: 30px 80px;
            h1{
                font-size: 64px;
                line-height: 70px;
                font-weight: 300;
                color: #fff;
                max-width: 50%;
            }
        }
    }

    #banner{
        h2{
            max-width: 90%;
            font-size: 22px;
        }
    }

    #rooms-slider{
        .item{
            .left{
                .overlay{
                    h2{
                        font-size: 35px;
                    }
                    p{
                        max-width: 100%;
                    }
                }
            }

        }
    }

    #news-slider {
        .item{
            p{
                line-height: 19px;
            }

        } 
    }
    
    .main-content{
        
        .room-boxes{
            .left, .right{
               
                h2{
                    font-size: 37px;
                }
            }
           
        }
        
        .rooms-bottom{
            .right{
                width: 100%;
            }
        }
    }



}


/*		Mobile Layout: 320px.
                Gutters: 24px.
                Outer margins: 34px.
                Inherits styles from: Default Layout.
---------------------------------------------
cols    1     2      3
px      68    160    252    */



@media only screen and (max-width: 767px) {

    .heading{


        .overlay{
            width: 100%; 
            background: rgba(41, 50, 83, 0.7);
            padding: 20px 40px 40px 40px;
            padding-bottom: 250px;

            h1{
                font-size: 36px;
                font-weight: 300; 
                color: #fff;
            }
            p{
                font-size: 14px;
                font-weight: 500; 
                line-height: 18px;
                color: #fff;
            }
        }
    }

    .main-content{
        margin: 0px;
        margin-top: -210px;
        .maintext{
            padding: 20px;
            
            
            
            .capacity{
                text-align: center;
            }
            
            h2{
                font-size: 28px;
                font-weight: 500;
                color: #002842;
            }
            h4{
                font-size: 18px;
                line-height: 21px;
            }
            p{
                font-size: 14px;
                line-height: 19px;
                font-weight: 500;
                
            }
            
            h3{
                font-size:23px;
            }
            
            #room-images{
                .owl-controls {
                    
                    

                    .owl-prev, .owl-next {
                        top: auto;
                        bottom: 10px;
                        transform: none;

                        border-radius: 50%;
                        height: 40px;
                        width: 40px;
                        text-align: center;
                        i{
                            color: $dark-blue;
                            line-height: 40px;
                            font-size: 16px;
                        }

                        &:hover{
                            background: rgba(255,255,255,0.9);
                        }
                    }

                    .owl-prev {

                        left: calc(50% - 50px);
                        i{
                            margin-right: 2px;
                        }

                    }

                    .owl-next {


                        right: calc(50% - 50px);
                        i{
                            margin-left: 3px;
                        }
                    }
                }
            }
        }
        
         .more-rooms{
            
                    padding: 30px 20px;
                    h2{
                        font-size: 28px;
                    }
                    p{
                        font-size: 13px;
                    }
         }
        .room-boxes{
            .left, .right{
                padding: 20px;
                height: auto;
                float: none;
                display: block; 
                
                h2{
                    font-size: 28px;
                    font-weight: 500;
                    margin-top: 0;
                    color: #002842;
                }
                p{
                    font-size: 13px;
                    font-weight: 500;
                    color: #002842;
                }
            }

            .left{
                
                width: 100%;

                &.dark{
                    background: $color2;
                    h2{

                        color: #fff;
                        margin-top: 0;
                    }
                    p{

                        color: #fff;
                    }
                }

            }
            .right {
                
                    width: 100%;
                    height: 192px;
               
            }
            
            



        }
        
        .rooms-boxes{
            .box{
                height: auto;
                .overlay{
                    padding: 30px 20px;
                    h2{
                        font-size: 28px;
                    }
                    p{
                        font-size: 13px;
                    }
                }
            }
        }
        
        .rooms-bottom{
            .right{
                width: 100%;
            }
        }


    }

    footer{
        padding: 20px 30px;
    }

    #news-slider {
        .item{
            padding: 40px 30px;
            height:auto;
            h2{
                font-size: 25px;
            }
        } 
    }


    #banner{
        h2{
            max-width: 100%;
            font-size: 20px;
        }
    }

    #header{


        .social{

            position: absolute;
            text-align: center;
            right: auto;
            top: auto;
            bottom: 11px;
            left: 10px; 
            a{
                padding: 0 10px;
            }

            .book-enq{
                display: none;    
            } 
        }

        #menu{

            height: 50px;

            .mobile-wrapper{
                position: fixed; 
                height: 100%;
                width: 220px;
                top: 0;  
                right: -220px; 
                background: $color2;
                -webkit-transition: all 0.3s ease-out;
                -moz-transition: all 0.3s ease-out;
                -o-transition: all 0.3s ease-out; 
                transition: all 0.3s ease-out;

                &.left{

                    right: 0;
                    overflow-y:auto;
                }

                ul{
                    position: absolute;
                    width: 220px;
                    top: 34px;
                    right: 0;
                    li{
                        width: 100%;
                        display: block;
                        margin: 0;
                        a{
                            display: block;
                            z-index: 100;
                            padding: 0 15px;
                            text-align: left;
                            font-size: 16px;      
                        }
                    }
                }
            }



        }


    }

    .boxes{
        height: auto;
        .left, .right{
            display: block; 
            float: none; 
            width: 100%; 

            .overlay{
                padding: 40px 30px;
                h2{
                    margin-top: 0px;
                }
                p{
                    max-width: 100%;
                }
            }
        }
    }

    .more-button{

        font-size: 12px;

        letter-spacing: 2px;

        padding: 7px 15px;
        margin-right: 6px;
        margin-top: 10px;

        i{
            font-size: 12px;
            margin-left: 3px; 
        }

        &.green{
            background: $green;
        }
        &.pink{
            background: $pink;
        }
        &.blue{
            background: $dark-blue;
        }

        &:hover{
            background: $color3;
            color: #fff;
        }
    }
    
    

    #slider{
        .item{
            height: 300px;
            padding: 20px 30px;
            h1{
                font-size: 8vw;
                line-height: 9vw;
                font-weight: 300;
                color: #fff;
                max-width: 100%;
                text-shadow: 0px 0px 5px #666; 

                &.dark{
                    color: #fff;
                }
            }
        }

        .owl-controls {

            .owl-prev, .owl-next {
                top: auto;
                bottom: 10px;
                transform: none;

                border-radius: 50%;
                height: 40px;
                width: 40px;
                text-align: center;
                i{
                    color: $dark-blue;
                    line-height: 40px;
                    font-size: 16px;
                }

                &:hover{
                    background: rgba(255,255,255,0.9);
                }
            }

            .owl-prev {

                left: calc(50% - 50px);
                i{
                    margin-right: 2px;
                }

            }

            .owl-next {


                right: calc(50% - 50px);
                i{
                    margin-left: 3px;
                }
            }
        }
    }
    #rooms-slider{
        .item{

            .left{

                .overlay{
                    span{
                        display: none;
                    }
                    padding: 20px 30px;
                    padding-bottom: 70px;

                }
                width: 100%; 
                float: none; 
                height: auto; 

            }
            .right{
                display: none; 
            }
        }

        .owl-controls {

            .owl-prev, .owl-next {
                top: auto;
                bottom: 15px;
                transform: none;

                border-radius: 50%;
                height: 40px;
                width: 40px;
                text-align: center;
                i{
                    color: $dark-blue;
                    line-height: 40px;
                    font-size: 16px;
                }

                &:hover{
                    background: rgba(255,255,255,0.9);
                }
            }

            .owl-prev {

                left: calc(50% - 50px);
                i{
                    margin-right: 2px;
                }

            }

            .owl-next {


                right: calc(50% - 50px);
                i{
                    margin-left: 3px;
                }
            }
        }
    }
    
    



}



/*		Wide Mobile Layout: 480px.
                Gutters: 24px.
                Outer margins: 22px.
                Inherits styles from: Default Layout, Mobile Layout.
------------------------------------------------------------
cols    1     2      3      4      5
px      68    160    252    344    436    */

@media only screen and (min-width: 480px) and (max-width: 767px) {


}


@media only screen and (max-width: 479px) {

    #slider{
        .item{
            height: 280px;
            padding: 20px 30px;
            h1{
                font-size: 11vw;
                line-height: 12vw;
                font-weight: 300;
                color: #fff;
                max-width: 100%;

                &.dark{
                    color: #fff; 
                }
            }
        }
    }
}

/*	Retina media query.
        Overrides styles for devices with a 
        device-pixel-ratio of 2+, such as iPhone 4.
-----------------------------------------------    */



@media 
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-device-pixel-ratio: 2) {


}