body{
    position: relative; 
    left: 0;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

body.left{
    left: -220px;
}

#header{
    background: $color1;
    text-align: center;
    padding-top: 25px;
    color: #fff;
    position: relative;
    
    
    
    #logo{
            display: block;
            margin-bottom: 20px;
    }
    
    .social{
        position: absolute;
        text-align: right;
        right: 100px;
        top: 30px;
        z-index: 10;
        a{
            display: inline-block;
            padding: 0 6px;
            color: #fff;
            i{
                
                font-size: 20px;
                
            }
            
            &:hover{
                color: $color3;
            }
        }
        
        .book-enq{
            cursor: pointer;
            display: block;
            font-size: 12px;
            color: #fff;
            font-weight: 700;
            background: $green;
            padding: 8px 18px;
            margin-right: 6px;
            margin-top: 10px;
            
            i{
                font-size: 12px;
                margin-left: 3px; 
            }
            
            &:hover{
                background: $color3;
                color: #fff;
            }
        }
    }
    #menu{
        background: $color2;
        position: relative;
        
         ul{
            list-style: none;
             li{
                display: inline-block;
                margin-right: -4px;
                position: relative;
                
                .plus{
                    position: absolute; 
                    right: 0;
                    width: 50px;
                    height: 50px;
                    @media(max-width: 767px){
                        display: flex!important;
                        align-items: center;
                        justify-content: center;
                    }
                    top: 0;
                    cursor: pointer;
                    i{
                        font-size: 30px;
                        color: #fff;
                    }
                }
                
                a{
                    padding: 0 17px;
                    line-height: 50px;
                    color: #fff;
                    display: block;
                    font-size: 14px;
                    font-weight: 500; 
                    &:hover{
                        background: #12333D;
                    }
                }
                .dropdown{
                    
                    position: absolute; 
                    top: 50px;
                    
                    @media(max-width: 767px){
                         position: relative; 
                         top: 0px;
                    }
                    width: 220px;
                    left: 0;
                    display: none; 
                    text-align: left; 
                    z-index: 100;
                    background: $color1;
                    
                    
                    li{
                        display: block;
                        width: 100%;
                        a{
                            display: block;
                            padding: 0 17px;
                            background: $color1;
                            line-height: 50px;
                            color: #fff;
                            display: block;
                            font-size: 14px;
                            font-weight: 500; 
                            text-align: left; 
                            &:hover{
                                background: #12333D;
                            }
                            
                        }
                    }
                }
                
                &:hover{
                    a{
                        background: #12333D; 
                    }
                    .dropdown{
                        
                        @media(min-width: 768px){
                            display: block;
                        }
                        
                    }
                }
            }
        }
    }
    
    $background: #3d566e;
    $color: #fff;
    $height-icon: 20px;
    $width-line: 35px;
    $height-line: 4px;

    $transition-time: 0.4s;
    $rotation: 45deg;
    $translateY: ($height-icon / 2);
    $translateX: 0;
    


    #hamburger-icon {
        
      
        
      width:$width-line;
      height:$height-icon;
      position:absolute;
      right: 20px;
      top: 13px;
      
      

      .line {
        display:block;
        background:$color;
        width:$width-line;
        height:$height-line;
        position:absolute;
        left:0;
        border-radius:($height-line / 2);
        transition: all $transition-time;
        -webkit-transition: all $transition-time;
        -moz-transition: all $transition-time;

        &.line-1 {
          top:0;
        }
        &.line-2 {
          top:50%;
        }
        &.line-3 {
          top:100%;
        }
      }
      &:hover, &:focus {
         .line-1 {
            transform: translateY($height-line / 2 * -1);
            -webkit-transform: translateY($height-line / 2 * -1);
            -moz-transform: translateY($height-line / 2 * -1);
         }
        .line-3 {
            transform: translateY($height-line / 2);
            -webkit-transform: translateY($height-line / 2);
            -moz-transform: translateY($height-line / 2);
        }  
      }
      &.active {
        .line-1 {
          transform: translateY($translateY) translateX($translateX) rotate($rotation);
          -webkit-transform: translateY($translateY) translateX($translateX) rotate($rotation);
          -moz-transform: translateY($translateY) translateX($translateX) rotate($rotation);
        }
        .line-2 {
          opacity:0;
        }
        .line-3 {
          transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
          -webkit-transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
          -moz-transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
        }
      }
    }



}
