
@font-face {
	font-family: 'Museo Sans';
	src: url('assets/fonts/MuseoSans-100.eot');
	src: url('assets/fonts/MuseoSans-100.eot?#iefix') format('embedded-opentype'),
		url('assets/fonts/MuseoSans-100.woff') format('woff'),
		url('assets/fonts/MuseoSans-100.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Museo Sans';
	src: url('assets/fonts/MuseoSans-300.eot');
	src: url('assets/fonts/MuseoSans-300.eot?#iefix') format('embedded-opentype'),
		url('assets/fonts/MuseoSans-300.woff') format('woff'),
		url('assets/fonts/MuseoSans-300.ttf') format('truetype');
	font-weight: 300;             
	font-style: normal;         
}  

@font-face {
	font-family: 'Museo Sans';
	src: url('assets/fonts/MuseoSans-500.eot');
	src: url('assets/fonts/MuseoSans-500.eot?#iefix') format('embedded-opentype'),
		url('assets/fonts/MuseoSans-500.woff') format('woff'),
		url('assets/fonts/MuseoSans-500.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Museo Sans';
	src: url('assets/fonts/MuseoSans-700.eot');
	src: url('assets/fonts/MuseoSans-700.eot?#iefix') format('embedded-opentype'),
		url('assets/fonts/MuseoSans-700.woff') format('woff'),
		url('assets/fonts/MuseoSans-700.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Museo Sans';
	src: url('assets/fonts/MuseoSans-900.eot');
	src: url('assets/fonts/MuseoSans-900.eot?#iefix') format('embedded-opentype'),
             url('assets/fonts/MuseoSans-900.woff') format('woff'),
             url('assets/fonts/MuseoSans-900.ttf') format('truetype');
	font-weight: 900;
	font-style: normal; 
}


@import "bootstrap"; 

img{
    max-width: 100%;
}

.error{
    color: red;
    font-size: 13px;
}


a,span{
    text-decoration: none;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

a:hover,a:focus,a:active, button:hover, button:focus, button:active {
    outline: none; 
    text-decoration: none;
}


ul{
    margin: 0;
    padding: 0;
}

.more-button{
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    color: #fff;
    font-style: italic;
    letter-spacing: 2px;
    font-weight: 700;
    background: $green;
    padding: 10px 18px;
    margin-right: 6px;
    margin-top: 10px;
        
    i{
        font-size: 12px;
        margin-left: 3px; 
    }

    &.green{
        background: $green;
    }
    &.pink{
        background: $pink;
    }
    &.blue{
        background: $dark-blue;
    }

    &:hover{
        background: $color3;
        color: #fff;
    }
}

@mixin gradient-bg($bg1, $bg2) {
    background: $bg1; /* Old browsers */
    background: -moz-linear-gradient(top,  $bg1 2%, $bg2 100%);
    background: -webkit-linear-gradient(top,  $bg1 2%,$bg2 100%); 
    background: linear-gradient(to bottom, $bg1 2%,$bg2 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$bg1}', endColorstr='#{$bg2}',GradientType=0 );
}


@import "header";
@import "home";
@import "slider";
@import "blog";
@import "responsive";           


