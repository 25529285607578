#slider{
    .item{
        height: 440px;
        background-size: auto 100%!important;
        padding: 40px 100px;
        
        h1{
            font-size: 76px;
            line-height: 76px;
            font-weight: 300;
            color: #fff;
            max-width: 50%;
            
            &.dark{
                color: #293253;
            }
        }
        
        
    }
    
    .owl-controls {
        overflow: auto;
        background: #fff;
        margin-top: 0;
        padding: 0;
        .owl-prev, .owl-next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            -webkit-transition: all 0.3s ease-out;
            -moz-transition: all 0.3s ease-out;
            -o-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
            background: rgba(255,255,255,0.7);
            border-radius: 50%;
            height: 50px;
            width: 50px;
            text-align: center;
            i{
                color: $dark-blue;
                line-height: 50px;
                font-size: 18px;
            }
            
            &:hover{
                background: rgba(255,255,255,0.9);
            }
        }
        
        .owl-prev {
           
            left: 15px;
            i{
                margin-right: 2px;
            }
            
        }
        
        .owl-next {
           
          
            right: 15px;
            i{
                margin-left: 3px;
            }
        }
    }
    
    
    
    

}


#rooms-slider{
    
    .item{
        
        .left, .right{
            width: 50%;
            display: inline-block;
            height: 370px;
            float: left;
            
            
            

        }
        
        .left{
            .overlay{
                padding: 40px 80px;
                width: 100%;
                height: 100%;
                background: rgba(255,255,255,0.7);
                
                span{
                    padding: 6px 12px; 
                    background: #fff;
                    color: #929497; 
                    text-transform: uppercase;
                    font-size: 10px;
                    font-weight: 900;
                    letter-spacing: 2px; 
                }
                
                h2{
                    font-size: 42px; 
                    color: #002842;
                    font-weight: 500;
                }
                
                p{
                    font-size: 16px; 
                    color: #002842;
                    font-weight: 500;
                    max-width: 70%;
                }
            }
        }
        
        
    }
    
    
    
    .owl-controls {
        overflow: auto;
        background: #fff;
        margin-top: 0;
        padding: 0;
        .owl-prev, .owl-next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            -webkit-transition: all 0.3s ease-out;
            -moz-transition: all 0.3s ease-out;
            -o-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
            background: rgba(255,255,255,0.7);
            border-radius: 50%;
            height: 50px;
            width: 50px;
            text-align: center;
            i{
                color: $dark-blue;
                line-height: 50px;
                font-size: 18px;
            }
            
            &:hover{
                background: rgba(255,255,255,0.9);
            }
        }
        
        .owl-prev {
           
            left: 15px;
            i{
                margin-right: 2px;
            }
            
        }
        
        .owl-next {
           
          
            right: 15px;
            i{
                margin-left: 3px;
            }
        }
    }
    
}

#news-slider{
    
    position: relative;
    .item{
        padding: 40px 80px 40px;
        width: 100%;
        height: 370px;
        background: #293253;

        span{
            padding: 6px 12px; 
            background: #BAD9B1;
            color: #1E555D; 
            text-transform: uppercase;
            font-size: 10px;
            font-weight: 900;
            letter-spacing: 2px; 
        }

        h2{
            font-size: 24px; 
            line-height: 28px;
            color: rgba(227, 75, 11, 1);
            font-weight: 500;
        }

        p{
            font-size: 16px; 
            color: #fff;
            font-weight: 500;
            
        }
    }
    
    .owl-controls {
        overflow: auto;
        background: #fff;
        margin-top: 0;
        padding: 0;
        .owl-prev, .owl-next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            -webkit-transition: all 0.3s ease-out;
            -moz-transition: all 0.3s ease-out;
            -o-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
            background: rgba(255,255,255,0.7);
            border-radius: 50%;
            height: 50px;
            width: 50px;
            text-align: center;
            i{
                color: $dark-blue;
                line-height: 50px;
                font-size: 18px;
            }
            
            &:hover{
                background: rgba(255,255,255,0.9);
            }
        }
        
        .owl-prev {
           
            left: 15px;
            i{
                margin-right: 2px;
            }
            
        }
        
        .owl-next {
           
          
            right: 15px;
            i{
                margin-left: 3px;
            }
        }
    }
    
    .owl-dots {
		text-align: center;
		-webkit-tap-highlight-color: transparent;
                position: absolute; 
                top: 20px;
                right: 30px;

		.owl-dot {
			display: inline-block;
			zoom: 1;
			*display: inline;

			span {
				width: 8px;
				height: 8px;
				margin: 7px;
				background: #fff;
				display: block;
				-webkit-backface-visibility: visible;
				transition: opacity 200ms ease;
				border-radius: 30px;
			}

			&.active,
			&:hover {
				span {
					background: #8BC53F;
				}
			}
		}
	}
}