#blog{
    
    .entry-summary{
        p{
            padding-left: 10px;
        }
    }
    
    .heading{
         background: url('/blog/wp-content/uploads/rooms.png') no-repeat left / cover; 
    }
    
    
    .comments{
        ul{
            list-style: none;
        }
    }
    .post{
        
        img{
            margin-bottom: 10px!important;
        }
        
        .wp-caption{
            width: auto!important; 
        }
        img{
            max-width: 100%!important;
            width: auto!important;
            height: auto!important;
        }
        
        
        ul{
            padding-left: 20px!important;
            margin-bottom: 20px!important; 
            li{
                font-size: 14px!important;
                color: #404041!important;
                a, span, div{
                    font-size: 14px!important;
                    color: #404041!important;
                }
            }
        }
    }

    .heading{
        h1{
            margin-top: 0;
            span{
                font-size: 18px; 
                color: pink;
                margin-top: 25px;
            }
        }
    }

    h2{
        font-size: 30px;
        font-weight: 500;
        margin-top: 0;
        color: #002842;
        
        a{
            color: #194750;
            
            &:hover{
                color: $dark-blue;
            }
        }
        
        @media (max-width: 767px){
            font-size: 25px;
        }
    }
    .nav-previous{
        float: left;
            margin-bottom: 40px;
    }
    
    .nav-next{
        float: right; 
            margin-bottom: 40px;
    }

    .nav-previous a, .nav-next a{

        cursor: pointer;
        display: inline-block;
        font-size: 14px;
        color: #fff;
        font-style: italic;
        letter-spacing: 2px;
        font-weight: 700;
        background: #388F80;
        padding: 10px 18px;
        text-transform: uppercase;

        span{
            display: none!important;
        }

        &:hover{
            background: $color3!important;
            color: #fff!important;
        }
    }

}

#blog a:hover{
    text-decoration: none;
    color: #23527c;
}

#blog .entry-meta a, #blog .entry-meta span{
    color: #23527c;
}

#blog  .entry-meta a { 

    color: $pink;
}



#blog article footer{
    padding: 10px 20px;
    margin-bottom: 30px;
    background: #eee;
    color: $color2;
    border-left: 5px solid #388F80;
    
    .tag-links{
        display: block; 
        line-height: 18px;
        a{
            font-size: 13px;
            color: #23527c;
        }
    }
    
    

}




#sidebar{
    ul{
        list-style: none;
    }
    .widget-container{
        padding: 40px;
        margin: 0;
        @media (max-width: 767px){
            padding: 20px;
        }

        h3{    
            font-size: 34px;
            color: #fff;
            font-weight: 300;
            margin-top: 0;
        }
        ul{
            list-style: none;
            li{
                a{
                    color: #fff;
                    margin-bottom: 5px;
                    display: block;
                    line-height: 17px;
                }
            }
        }

        .tagcloud{
            a{
                color: #fff;
            }
        }
    }    

    .widget-container:nth-child(1){
        background: rgba(199, 193, 0, 0.8);
        ul{
            li{
                a{
                    margin-bottom: 17px;
                    &:hover{
                        color: $color1;
                    }
                }
            }

        }
    }
    .widget-container:nth-child(2){
        background: rgba(227, 75, 11, 0.8);
        ul{
            li{
                a{
                    &:hover{
                        color: $dark-blue;
                    }
                }
            }

        }
    }
    .widget-container:nth-child(3){
        background: rgba(30, 85, 93, 0.8);
        ul{
            li{
                a{
                    &:hover{
                        color: rgba(199, 193, 0, 1);
                    }
                }
            }

        }
    }
    .widget-container:nth-child(4){
        background: #293253;
        .tagcloud{ 
            a{
                &:hover{
                    color: $pink;
                }
            }

        }
    }

} 



